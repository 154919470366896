import React, { useState } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { BodyTextM, BodyTextS } from './text';
import { RoundedButton } from './button';
import { DividerContainer } from './decocration';
import styled from 'styled-components';
import { LINK_DISABLED } from '../utils/constants';


const ButtonLink = ({ to, children, className, downloadLink }) => {
  if (downloadLink) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" className="">
        <RoundedButton className={className}>
          {children}
        </RoundedButton>
      </a>
    );
  }

  return (
    <a
      href={to}
      onClick={(e) => {
        e.preventDefault();
        navigate(to)
      }}
    >
    <RoundedButton className={className}>
      {children}
    </RoundedButton>
    </a>
  );
};

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  downloadLink: PropTypes.bool,
};

ButtonLink.defaultProps = {
  className: '',
  downloadLink: false,
};

const StyledLink = styled.a`
@media (min-width: 1024px) {
  position:relative;
  top:1.3rem;
}
`

export interface NavigationLinkProperties {
  to: string;
  children: string | JSX.Element;
  className: string;
}

const ParentNavigationLink =  ({ to, children, className } : NavigationLinkProperties) => {
  const linkDisabled = to === LINK_DISABLED;
  const hoverClasses = linkDisabled ? "hover:no-underline cursor-default" : "hover:underline";

  return (
    <a href={to}
      className={`font-book text-base ${className} ${hoverClasses}`}
      onClick={(e) => {
        e.preventDefault();
        if (!linkDisabled) {
          navigate(to);
        }
      }}
    >
      {children}
    </a>
  );
};

const NavigationLink =  ({ to, children, className }: NavigationLinkProperties) => {
  return (
    <a href={to}
      className={`font-book text-base hover:underline ${className}`}
      onClick={(e) => {
        e.preventDefault();
        navigate(to)
      }}
    >
      {children}
    </a>
  );
};

NavigationLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

NavigationLink.defaultProps = {
  className: '',
};

const ExternalLink = ({ to, children, className }) => {
  return (
    <a
      className={`font-book text-red text-base leading-loose
        cursor-pointer hover:underline ${className}`}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

ExternalLink.defaultProps = {
  className: '',
};

const PreviewLink = ({ image, tag, title, description, linkTo }) => {
  const [underlineStyle, setUnderlineStyle] = useState('');
  return (
    <a
      href={linkTo}
      onMouseEnter={() => setUnderlineStyle('underline')}
      onMouseLeave={() => setUnderlineStyle('no-underline')}
      onClick={(e) => {
        e.preventDefault();
        navigate(linkTo)
      }}
      className="h-auto min-h-404px lg:max-w-390px text-center bg-white"
    >
      <div className="h-full">
        <Img
          className="w-full h-56"
          fluid={
            image.localFile
              ? image.localFile.childImageSharp.fluid
              : image.fluid
          }
          alt={image.title}
        />
        <DividerContainer>{tag}</DividerContainer>
        <div className={`px-5 pb-5 ${underlineStyle}`}>
          <BodyTextM bold={true} className="mt-6 mx-4 leading-extra-loose">
            {title}
          </BodyTextM>
          <BodyTextS className="mt-2 leading-loose">{description}</BodyTextS>
        </div>
      </div>
    </a>
  );
};

export { ButtonLink, NavigationLink, ExternalLink, PreviewLink, ParentNavigationLink };
